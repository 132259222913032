<template>
    <div id="cameraVideo">
        <div class="filter-panel">
            <input
                v-model="searchTerm.vagueSearch"
                type="text"
                class="cs-input"
                style="width:200px"
                placeholder="搜索监控编号/位置"
            />
            <button
                type="button"
                class="btn btn-primary btn-sm"
                @click="getVideoData()"
            >查询</button>
        </div>
        <div class="result-panel">
            <table
                class="footable table table-stripped toggle-arrow-tiny"
                data-page-size="15"
            >
                <thead>
                    <tr>
                        <th>添加时间</th>
                        <th>监控编号</th>
                        <th>位置</th>
                        <th>录像详情</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="camera in cameraList"
                        :key="camera.id"
                    >
                        <td>{{camera.addTime}}</td>
                        <td>
                            <span
                                class="allow-click"
                                @click="videoDetail()"
                            >
                                {{camera.cameraCode}}
                            </span>
                        </td>
                        <td>{{camera.position}}</td>
                        <td>
                            <span
                                class="allow-click"
                                @click="checkCamera(camera)"
                            >
                                {{camera.videoDetail}}
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
            <Pagination
                name="Pagination"
                componentName="Pagination"
            ></Pagination>
            <TargetPersonVideo></TargetPersonVideo>
        </div>
    </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import TargetPersonVideo from "@/components/SecurityCamera/targetPersonVideo";
export default {
    components:{
        Pagination,
        TargetPersonVideo,
    },
    data(){
        return {
            searchTerm:{
                vagueSearch:"",
            },
            cameraList:[
                {id:1,addTime:"2012-02-21",cameraCode:"001",position:"楼内",videoDetail:"10"},
                {id:2,addTime:"2015-05-13",cameraCode:"002",position:"门外",videoDetail:"10"},
                {id:3,addTime:"2012-08-30",cameraCode:"003",position:"室内",videoDetail:"10"},
            ]
        }
    },
    methods:{
        getVideoData(){
            console.log(this.searchTerm)
        },
        checkCamera(target){
            var delId = target.id;
            var cameraId = target.cameraCode;
            var position = target.position;
            this.$router.push({
                name:"cameraVideoDetail",
                params:{
                    id:delId,
                    cameraId,
                    position,
                }
            })
        },
        videoDetail(target){
            var data = {
                boolean:true,
            }
            this.$vc.emit(this.$route.path, "cameraVideo","checkVideo",data)
        }
    }
}
</script>

<style>

</style>
